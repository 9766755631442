@import '~normalize.css', '~reset-css/reset.css';

@import 'variables', 'mixins';

html {
    &,
    &.wf-loading {
        visibility: hidden;
    }
}

.wf-active {
    visibility: visible;
}

* {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    &,
    &::before,
    &::after {
        box-sizing: border-box;
    }

    &::placeholder {
        transition: color 0.3s ease;
    }

    &:focus::placeholder {
        color: transparent;
    }
}

html {
    font-size: 62.5%;
}

body {
    @include font-styles-for($primary-font-regular);
    @include font-size(1.8);
    color: $body-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.14;
    margin: 0 0 30px;
}

h1,
h2,
h3 {
    @include font-styles-for($primary-font-light);
}

h1 {
    @include font-size(6);
}

h2 {
    @include font-size(3.2);
}

h3 {
    @include font-size(2.4);
}

h4,
h5,
h6 {
    @include font-size(2);
}

p {
    line-height: normal;
}

a {
    color: inherit;
}

img {
    height: auto;
    max-width: 100%;
}

b,
strong {
    @include font-styles-for($primary-font-bold);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.page-navigation__menu {
    display: flex;
    justify-content: center;
}

.page-navigation__link {
    @include font-size(3);
    display: block;
    padding: 0 20px;
    text-decoration: none;
    transition: opacity 0.3s;
    will-change: opacity;

    &:hover {
        opacity: 0.5;
    }
}
