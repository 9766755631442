@import 'variables', 'mixins';

.homepage {
    animation: animateBackgroundGradient 30s ease-in-out infinite;
    background: linear-gradient(225deg, $homepage-gradients);
    background-size: 500% 500%;
    min-height: 100vh;
    will-change: background-position;

    .page-container {
        text-align: center;
    }

    .page-frame {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
        width: 100%;
    }

    .page-content__header {
        @include font-styles-for($primary-font-regular);
    }

    .page-title,
    .page-subtitle,
    .page-navigation,
    .scroll-to {
        opacity: 0;
    }

    .page-subtitle {
        @include font-size(1.4);
        text-transform: uppercase;

        &__group:first-child {
            display: block;
            margin-bottom: 20px;
            padding-bottom: 20px;
            position: relative;

            &::after {
                bottom: -6px;
                content: '&';
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
            }

            .page-subtitle__divider {
                display: none;
            }
        }

        &__divider {
            padding: 0 5px;
        }
    }

    .scroll-to {
        display: block;
        margin-top: 60px;
    }
}

.wf-active .homepage {
    .page-title {
        animation: fadeIn 0.5s 0.3s forwards;
    }

    .page-subtitle {
        animation: fadeIn 0.5s 0.8s forwards;
    }

    .page-navigation {
        animation: fadeIn 0.5s 1.3s forwards;
    }

    .scroll-to {
        animation: fadeIn 0.5s 1.8s forwards;
    }
}

@keyframes animateBackgroundGradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (min-width: 375px) {
    .homepage .page-subtitle {
        @include font-size(1.6);
    }
}

@media only screen and (min-width: 640px) {
    .homepage .page-subtitle {
        @include font-size(2);

        &__group:first-child {
            margin-bottom: 0;
            padding-bottom: 0;

            &::after {
                display: none;
            }

            &,
            .page-subtitle__divider {
                display: initial;
            }
        }

        &__divider {
            padding: 0 10px;
        }
    }
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    .page-container {
        padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
    }
}
