@import './mixins';

@media only screen and (min-width: 0px) {
    .instagram {
        &-feed {
            display: flex;
            flex-direction: column;
            padding: 30px;
            width: 100%;
            opacity: 0;

            &.animate {
                animation: fadeIn 0.5s 0.3s forwards;
            }

            &__items {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                list-style: none;
                margin: -30px -10px;
            }
        }

        &-item {
            margin: 10px;

            &__link {
                display: block;
                height: 100%;
            }

            &__image {
                height: 100%;
                max-width: none;
                object-fit: cover;
                width: 100%;
            }

            &__content {
                @include font-size(1.6);
                left: 50%;
                opacity: 0;
                position: absolute;
                text-align: center;
                top: 50%;
                transform: translate(-50%, -50%);
                transition: opacity 0.3s;
                width: 80%;
                z-index: 1;
            }

            &__caption {
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                margin-bottom: 10px;
                overflow: hidden;
            }
        }
    }

    .hexagon {
        height: calc(50vw * 1.2);
        margin: 30px 10px;
        width: calc(50% - 20px);

        &:last-child {
            margin: 30px auto;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &,
        &-side {
            display: block;
            overflow: hidden;
        }

        &-side {
            height: 100%;
            transform-origin: 50% 50%;
            width: 100%;
        }

        &-side--right {
            position: relative;
            top: 50%;
            transform: translateY(-50%) rotate(60deg);
        }

        &-side--left {
            transform: rotate(-120deg);
        }

        &__content {
            background: {
                position: center;
                size: cover;
            }
            box-shadow: inset 0 0 100px 60px rgba(black, 0);
            height: 100%;
            position: relative;
            top: 50%;
            transform: translateY(-50%) rotate(60deg);
            transition: box-shadow 0.3s, transform 0.3s;
            width: 100%;
        }
    }
}

@media only screen and (min-width: 400px) {
    .hexagon {
        height: calc(55vw * 1.2);
    }
}

@media only screen and (min-width: 480px) {
    .hexagon {
        height: calc(62vw * 1.2);
    }
}

@media only screen and (min-width: 768px) {
    .instagram-item {
        &:hover &__content {
            opacity: 1;
        }
    }

    .hexagon {
        height: calc(22vw * 1.2);
        margin: 30px 10px;
        width: calc(20% - 20px);

        &:last-child {
            margin: 30px 10px;
            position: inherit;
            top: 0;
            transform: translateY(0);
        }

        &:hover &__content {
            box-shadow: inset 0 0 100px 60px rgba(black, 1);
            transform: translateY(-50%) rotate(60deg) scale(1.05);
        }
    }
}
