@mixin font-size($size) {
    font-size: ($size * 10) + px;
    font-size: $size + rem;
}

@mixin font-styles-for($font) {
    font: {
        family: map-get($font, family);
        style: map-get($font, style);
        weight: map-get($font, weight);
    }
}

@mixin font-awesome-icon($unicode, $icon-size: false) {
    content: unquote('"\\#{$unicode}"');
    font-family: FontAwesome;

    @if $icon-size {
        @include font-size($icon-size);
    }
}
