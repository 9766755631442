$_primary-font-family: 'Ubuntu', sans-serif;

$primary-font-light: (
    family: $_primary-font-family,
    style: normal,
    weight: 300,
);

$primary-font-regular: (
    family: $_primary-font-family,
    style: normal,
    weight: 400,
);

$primary-font-bold: (
    family: $_primary-font-family,
    style: normal,
    weight: 700,
);

$_color-white: #fff;
$_color-picton-blue: #44b6ff;
$_color-tall-poppy: #c0392b;

$body-color: $_color-white;

$homepage-gradients: $_color-picton-blue, $_color-tall-poppy;
